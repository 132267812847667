.sheet {
    background:#fff;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    overflow:hidden;
    &.rounded {
        border-radius:4px;
    }
    &.inline {
        display:inline-block;
    }

    .form-header {
        font-size:13px;
        h3 {
            font-size:16px;
            font-weight:500;
            margin:0;
            padding:0;
        }
    }

    .form-content {
        input[type="text"] {
            width:100%;
            margin:0;
            padding:18.5px 14px;
            border-radius:4px;
            height:50px;
            margin-bottom:10px;
        }
        select {
            margin-bottom:10px;
            height:50px;
        }
        textarea {
            border:1px solid $fieldBorderColor;
        }
        border-top:1px solid $formBorderColor;
        border-bottom:1px solid $formBorderColor;
    }

    .form-action {
        .cell {
            margin-bottom:0;
        }
    }

    .form-header,.form-content, .form-action {
        padding:15px 30px;
    }

}

.dot {
    height:6px;
    width:6px;
    background: #999;
    display: inline-block;
    border-radius:100%;
    top:-1px;
    position:relative;
}

.section--ui {
    background:$bodyBg;
    min-height:90vh;
    padding-top: 50px;

    .doc-tile {
        padding:40px;
        background:#fff;
        margin-bottom:40px;
        h2 {
            font-weight:600;
            font-size:30px;
        }
    }

    .grid-container {
        //max-width:1300px;
    }

    .filter-list {
        .filter {
            border-radius:5px;
            font-size:12px;
            font-weight:600;
            border:1px solid #5d656c;
            cursor:pointer;
            display:inline-block;
            margin-right:5px;
            padding:5px 8px;
            &.active {
                background:#5d656c;
                color:#fff;
            }
        }
    }
    .ui-top-nav {
        h3 {
            font-weight:500;
        }
        margin-bottom:20px;
    }

    .add-event-row {
        .cell {
            margin-bottom:0px !important;
            input {
                margin-bottom:0px !important;
            }
        }
    }

    .header-row {
        .cell {
            font-weight:600;
            font-size:13px;
        }
    }

    .added-events {
        tr {
            td{
                padding:15px !important;
            }
            .actions {
                opacity:0;
                cursor:inital;
                pointer-events:none;
                .delete-event {
                    margin-left:10px;
                    color: #e74c3c;
                }
            }
            &:hover, &.event-selected {
                background:#f8fcab !important;
                .actions {
                    opacity:1;
                    pointer-events:inherit;
                    cursor:pointer;
                }
            }
            
        }
    }

    .ui-button {
        width:50px;
        height: 50px;
        min-width: 50px;
        border-radius:100%;
        padding:0;
        line-height:50px;
        cursor:pointer;
        font-size:18px;
    }

    .ask-button {
        font-size:14px;
        padding:10px 20px;
        border-radius:10px;
    }

    .ave-button {
        padding:10px;
        i {
            font-size: 12px;
            background: #20a95d;
            color: #fff;
            text-align: center;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin-right: 3px;
        }
    }
    table {
        margin:0px;
        tbody,
        tfoot,
        thead {
            border:0px solid;
        }

        thead {
            background:$tableHead;
            border:0px solid;
            border-radius:0px;

            tr {
                td {
                    color: #fff;
                    font-weight: 600;
                    padding: 15px 20px;
                    font-size: 13px;
                    letter-spacing: 0px;
                }
                
            }
        }

        tbody td, tbody th {
            padding: 15px 20px;
            font-size: 13px;
        }

        tbody {
            tr {
                border-bottom:1px solid #ececf3;
                transition: ease-in-out .4s;
                &:nth-child(2n) {
                    background:#fff;
                }
                &.manage-link {
                    cursor:pointer;
                    &:hover {
                        background:$tableRowHover;
                    }
                }
            }
            td {
                .button {
                    font-size:10px;
                    padding:8px 16px;
                }
                
                i {
                    color: #444;
                    &.fa-download,
                    &.fa-pencil {
                        border-radius: 100%;
                        padding: 7px;
                    }

                    &.fa-download {
                        background:$accentColor;
                        color:#fff;
                    }

                    &.fa-pencil {
                        background:#555;
                        color:#fff;
                    }
                }
            }
        }
    }

    

    .form {
        padding:0;
        background:transparent;

        textarea {
            min-height:200px !important;
            font-family: 'Courier New', Courier, monospace;
        }

        label {
            font-size:12px;
            font-weight:600;
            color: #555;
        }
        input {
            border:1px solid $fieldBorderColor;
            padding:18.5px 14px;
            border-radius:4px;
        }
        .cell {
            margin-bottom:10px;
        }
    }

    .tabs {
        background: transparent;
        border:0px solid;
        margin-top:30px;
        
        .tabs-title {

            a {
                background: $colorGreyBlue;
                color:#222;
                font-weight:600;
                border-top:3px solid $colorGreyBlue;
                &:hover {
                    opacity:1;
                }
            }
        }
    }
    .tabs-title>a[aria-selected=true] {
        background: #fff;
        color: $colorPurple;
        border-top: 3px solid $colorPurple;
    }

    .tabs-content {
        padding:20px;
        border: 0px solid #d0d5dc;
        border-top: 0;
        background: #fff;
        min-height: 180px;
        label {
            display:none;
        }

    }

    // .ui-top-nav {
    //     ui-
    // }

    .delete-link, .archive-link {
        display:inline-block;
        margin-top:0px;
        padding:5px;
        font-weight:600;
        cursor: pointer;

        i {
            margin-right:5px;
        }
        color:#e74c3c;
    }

    #seminars-panel {
        table {
            td {
                //font-family: 'Courier New', Courier, monospace;
                font-size:13px;
                padding:10px 10px;
            }
            tr:nth-child(even){
                background: #f1f1f1;
            }
        }
    }
}

.seminars-ui-menu {
    .cell {
        margin-bottom:0 !important;
    }
}
.new-region-form {
    textarea {
        font-size:12px !important;
        font-family: 'Courier New', Courier, monospace;
    }
}

select.default-location {
    display: inline-block;
    max-width: 200px;
    font-size: 12px;
    font-weight: 500;
    margin:0;
    margin-left: 10px;
}

.form-message {
    color:#e74c3c;margin-bottom:20px;text-align:center;font-weight:600;font-size:14px;
}

h3 {
    font-weight:600;
}


.login-page {
    .section--ui {
        background:  #372592;
    }
    h3 {
        color:#fff;
    }
    .log-in-form {
        background:#fff;
        padding:40px;
        margin-top:20px;
        box-shadow:0px 1px 15px rgba(0,0,0,.7);
    }
}