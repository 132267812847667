@media print, screen and (min-width: 64em) {
    .off-canvas-content.has-reveal-left, .position-left.reveal-for-large~.off-canvas-content {
        margin-left: 300px;
    }
}
.off-canvas {
    background:$navBg;
    width: 300px;
    box-shadow: none !important;
    border-right:1px solid #eee;
    .logo {
        color:#222;
        width:100%;
        text-align:left;
        padding: 30px;
        font-size: 30px;
        font-weight: 600;
        line-height:1.1;
    }
    .ui-menu {
        border-top: 1px solid #eee;
        padding-top: 15px;
    }
    .menu { 
        li {
            a {
                color:#333;//#37474f;// #536e7a; //#37474f;
                font-weight: 600;
                padding: 15px 15px;
                margin:0 30px;
                font-size: 14px;
                transition: ease-in-out .2s;
                &.disabled {
                    pointer-events: none;
                    opacity:.4;
                    cursor:disabled;
                }
                &:hover, &.active {
                    color:$navHover;
                    opacity: 1;
                    background:rgba(38, 50, 56, 0.08)
                }
                svg, i {
                    vertical-align: middle;
                    text-align:center;
                    font-size:18px;
                    margin-right:10px;
                    min-width:30px;
                }
            }
        }
    }

    .tile {
        padding: 20px;
        background: #fff;
        margin-top: 20px;
        box-shadow: 0px 0px 5px rgba(0,0,0,.1);
    }
}