@import '_settings';

// vendors && plugins 
@import '_vendor/foundation-full';
@import '_vendor/aos';
@import '_vendor/swiper';
@import '_vendor/datepicker.min';

// FontAwesome
@import "_fontawesome/scss/fontawesome";
@import "_fontawesome/scss/solid";
@import "_fontawesome/scss/regular";
@import "_fontawesome/scss/light";
@import "_fontawesome/scss/brands";

// base
@import '_base';

// sections
@import '_sections/section';
@import '_sections/header';
@import '_sections/footer';
@import '_sections/off-canvas';
@import '_sections/ui';

// components
@import '_components/checklist';
@import '_components/cards';
@import '_components/popup';
@import '_components/forms';
@import '_components/block';
@import '_components/accordion';


.section--hero {
    padding:140px 0;
}
// pages
